import React from "react";
import { Form, Button, Select, Switch, Slider, Drawer, Col, Row, Checkbox, Tooltip} from "antd";
import { CloseOutlined, CheckOutlined, FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { mediaType, enableValues, metricOptions } from "./utils";
import "./ControlsForm.less";

const { Option } = Select;
class ControlsForm extends React.Component {

  constructor(props) {
    super(props);

    this.state ={
      visible: false,
      defaultFormValues: {
        allSources: true,
        articles: true,
        sourceReach: false,
        sources: [],
        rangeReliability: [0, 64],
        rangeBias: [-42, 42],
        rangeAudience:[0,268325000],
        mediaType: mediaType,
        csvSelect: [22,32],
      },
    }
  }

  formRef = React.createRef();
  
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onAllSourcesChange = (value) => {
    if (value) {
      this.formRef.current.setFieldsValue({
        sources: [],
      });
    }
    this.onFinish();
  };

  onSourcesChange = (value) => {
    if (value) {
      this.formRef.current.setFieldsValue({
        allSources: false,
      });
    this.onFinish();
    }
  };

  onMetricChange = () => this.onFinish();

  onFinish = () => {
    this.props.formSubmit(this.formRef.current.getFieldsValue());
  };

  onReset = () => {
    this.formRef.current.resetFields();
    this.props.chartReset();
    this.onFinish();
  };

  render() {

    const { sources, chartLoading } = this.props;

    var mediaTypeOptions = [
      {label: 'Unknown', value: 1, disabled: !enableValues[0]},
      {label: 'Website / Articles', value: 2, disabled: !enableValues[1]},
      {label: 'TV / Video', value: 3, disabled: !enableValues[2]},
      {label: 'Podcast / Audio', value: 4, disabled: !enableValues[3]},
    ];

    let formSources = [];

    if (this.formRef.current) {
      formSources = this.formRef.current.getFieldValue("sources");
    }

    return (
      <Form
        ref={this.formRef}
        name="control-ref"
        layout="horizontal"
        className="formControls"
        initialValues={this.state.defaultFormValues}
      >
        <Form.Item
              name="sources"
              label="Select specific news sources"
              className="sourcesArea verticalField"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Type the source name"
                disabled={!chartLoading}
                onChange={this.onSourcesChange}
                allowClear
              >
                {sources
                  .filter((o) => !formSources.includes(o.moniker_name))
                  .map((source) => (
                    <Option key={source.moniker_name} value={source.moniker_name}>
                      {source.moniker_name}
                    </Option>
                  ))}
              </Select>
        </Form.Item>

        <Form.Item
          name="rangeReliability"
          label="Filter source reliability range"
          className="rangeReliabilityArea verticalField"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Slider
            range
            marks={{ 0: "0", 64: "64" }}
            min={0}
            max={64}
            disabled={!chartLoading}
            onChange={this.onFinish}
          />
        </Form.Item>
        <Form.Item
          name="rangeBias"
          label="Filter source bias range"
          className="rangeBiasArea verticalField"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Slider
            range
            marks={{ "-42": "-42", 0: "0", 42: "42" }}
            min={-42}
            max={42}
            disabled={!chartLoading}
            onChange={this.onFinish}
          />
        </Form.Item>
        
        <Button
          htmlType="button"
          size="large"
          onClick={this.onReset}
          disabled={!chartLoading}
          className="resetButton resetButtonArea"
        >
          <UndoOutlined /> Reset
        </Button>

        <Tooltip
          title="Customize Chart as You Like"
          color="geekblue"
          placement="right"
        >
          <Button
          size="large"
          onClick={this.showDrawer}
          disabled={!chartLoading}
          className="filterButton moreFilterArea"
          >
            <FilterOutlined />Filters
          </Button>
        </Tooltip>
        
        <Form.Item name="drawerRight">
          <Drawer
            title="Customize Chart"
            width={300}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
            name="drawer"
            forceRender="true"
          >
            <Row gutter={32}>
                <Col span={24}>
                  <Form.Item
                    name="allSources"
                    label="Display all sources"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={this.onAllSourcesChange}
                    />

                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                  name="articles"
                  label="Display content piece data"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={this.onFinish}
                  />
                </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Form.Item
                  name="sourceReach"
                  label="Display audience size"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={this.onFinish}
                  />
                </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="rangeAudience"
                    label="Filter audience range"
                    className="verticalField"
                  >
                    <Slider
                      range
                      marks={{ 0: "0", 268325000: "Highest" }}
                      min={0}
                      max={268325000}
                      step={1000}
                      onChange={this.onFinish}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Form.Item
                      name="mediaType"
                      label="Media Type"
                  >
                    <Checkbox.Group
                      name="mediabox"
                      options={mediaTypeOptions}
                      onChange={this.onFinish}
                    >
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col span={24}>
                  <Form.Item
                      name="csvSelect"
                      label="Content Piece CSV Metric Selection"
                  >
                    <Checkbox.Group
                      name="metricbox"
                      options={metricOptions}
                      onChange={this.onMetricChange}
                      style={{columns: 2}}
                    >
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
          </Drawer>
        </Form.Item>
      </Form>
    );
  }
}

export default ControlsForm;
