import React from "react";
import axios from "axios";
import * as d3 from "d3";
import * as canvg from "canvg";
import * as utils from "./utils";
import { cleanArticles } from "./calculation";
import "./App.less";
import { Chart, width, height } from "./chart";
import ControlsForm from "./ControlsForm";
import { Button, Tooltip, Spin } from "antd";
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import JSZip from "jszip";
import FileSaver from "file-saver";
import queryString from 'query-string';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formValues: {},
      dimensions: { top: 0, left: 0 },
      chartLoading: false,
      sources: [],
      articles: []
    };
  }

  callAPI = async() => {
    try {
        let BATCH_ID = queryString.parse(window.location.search)['batch_id'];
        let no_search_batches = ["1651", "4574","4612","5240"]
        if (no_search_batches.includes(BATCH_ID)){
          console.log("Data Error! Please use some other batch id.");
          return 200;
        }
        await axios.get(`/data-proxy/${BATCH_ID}/`).then(response => {
        const { articles, sources } = cleanArticles(response.data);
        this.setState({sources: sources, articles: articles});
        
        // Filter media type
        let selectedSources = sources.filter((source) => utils.mediaType.includes(source.mediatype));
        let selectedArticles = articles.filter((article) => utils.mediaType.includes(article.mediatype));
        this.chart = new Chart(selectedArticles, selectedSources, true);
        this.chart.create()
        this.setState({chartLoading: true})
      });
    } catch {
      console.log("Data Error!");
    } 
  }

  componentDidMount() {
    this.callAPI();
  }

  formSubmit(values) {
    this.setState(
      {
        formValues: values,
      },
      () => this.drawChart()
    );
  }

  drawChart() {
    const { formValues, sources, articles } = this.state;
    let cleanArticles, cleanSources, cleanSourceNames, selectedSources;

    // Filter media type
    if (formValues.mediaType) {
      let filteredMedia = utils.mediaType.filter((type) => formValues.mediaType.includes(type));
      selectedSources = sources.filter((source) => filteredMedia.includes(source.mediatype));
    }

    // Source select
    if (formValues.allSources) {
      cleanSources = selectedSources;
    }

    else if (formValues.sources.length > 0) {
      cleanSources = selectedSources.filter((source) =>
        formValues.sources.includes(source.moniker_name)
      );
    }

    else {
      cleanSources = [];
    }

    // Filter ranges
    const { rangeBias, rangeReliability, rangeAudience } = formValues;
    cleanSources = cleanSources
      .filter(
        (source) =>
          source.reliability_mean >= rangeReliability[0] &&
          source.reliability_mean <= rangeReliability[1]
      )
      .filter(
        (source) =>
          source.bias_mean >= rangeBias[0] &&
          source.bias_mean <= rangeBias[1]
      )
      .filter(
        (source) =>
          source.reach >= rangeAudience[0] &&
          source.reach <= rangeAudience[1]
      );

    cleanSourceNames = cleanSources.map((source) => source.moniker_name);

    // Article select
    if (!formValues.articles) {
      cleanArticles = [];
    }
    else {
      cleanArticles = articles
      .filter(
        (article) => cleanSourceNames.includes(article.moniker_name)
      );
    }

    const { sourceReach } = formValues;

    this.chart.update(cleanArticles, cleanSources, { sourceReach });
  }

  chartReset() {
    this.chart.reset();
  }

  dataExport() {

    const { formValues, sources, articles } = this.state;
    const { rangeBias, rangeReliability, rangeAudience, mediaType, sourceReach, csvSelect } = formValues;

    let defaultSources, selectedSources;
    let filteredSources, excludedSources;
    let filteredSourcesName, excludedSourcesName;
    let filteredContentPieces, excludedContentPieces;

    // Filter media type
    if (mediaType) {
      let filteredMedia = utils.mediaType
          .filter(
            (type) => mediaType.includes(type)
          );
      
      selectedSources = sources
      .filter(
        (source) => filteredMedia.includes(source.mediatype)
      );
    }

    else {
      selectedSources = sources
      .filter((source) => utils.mediaType.includes(source.mediatype)
      );
    }
    
    defaultSources = selectedSources;

    // filter source and ranges for accuracy, bias and audience
    if (Object.keys(formValues).length === 0){  
      
      filteredSources = defaultSources ;
      
      filteredSourcesName  = defaultSources
      .map(
        (source) => source.moniker_name
      );
      
      excludedSources = [];
      excludedSourcesName = [];
      
      filteredContentPieces = articles
      .filter(
        (article) => filteredSourcesName.includes(article.moniker_name)
      );
      
      excludedContentPieces = [];
    }

    else {

      if (formValues.allSources) {
        filteredSources = defaultSources ;
      }
      
      if (formValues.sources.length > 0) {
        filteredSources = defaultSources
        .filter(
          (source) => formValues.sources.includes(source.moniker_name)
        );
      }

      if (
          (rangeReliability[0] !== 0 || rangeReliability[1] !== 64) ||
          (rangeBias[0] !== -42 || rangeBias[1] !== 42) ||
          (rangeAudience[0] !== 100000 || rangeAudience[1] !== 268325000)
        ) {

        filteredSources = filteredSources
        .filter(
          (source) => source.reliability_mean >= rangeReliability[0] && 
                      source.reliability_mean <= rangeReliability[1]
        )
        .filter(
          (source) => source.bias_mean >= rangeBias[0] &&
                      source.bias_mean <= rangeBias[1]
        )
        .filter(
          (source) => source.reach >= rangeAudience[0] &&
                      source.reach <= rangeAudience[1]
        );
      }

      excludedSources = defaultSources
      .filter(
        (el) => !filteredSources.includes(el)
      );

      filteredSourcesName  = filteredSources
      .map(
        (source) => source.moniker_name
      );

      excludedSourcesName = excludedSources
      .map(
        (source) => source.moniker_name
      );
      
      filteredContentPieces = articles
      .filter(
        (article) => filteredSourcesName.includes(article.moniker_name)
      );

      excludedContentPieces = articles
      .filter(
        (article) => excludedSourcesName.includes(article.moniker_name)
      );
  }

    // create final list of required columns for source csv
    filteredSources = filteredSources
                      .map(({moniker_name, domain, bias_mean, reliability_mean, reach, mediatype}) =>
                        (sourceReach ?
                          `\n"${moniker_name}",${domain},${bias_mean.toFixed(2)},${reliability_mean.toFixed(2)},${reach},${getKeyByValue(utils.mediaName,mediatype)}`:
                          `\n"${moniker_name}",${domain},${bias_mean.toFixed(2)},${reliability_mean.toFixed(2)},${getKeyByValue(utils.mediaName,mediatype)}`
                        ));
                        
    excludedSources = excludedSources
                      .map(({moniker_name, domain, bias_mean, reliability_mean, reach, mediatype}) =>
                        (sourceReach ?
                          `\n"${moniker_name}",${domain},${bias_mean.toFixed(2)},${reliability_mean.toFixed(2)},${reach},${getKeyByValue(utils.mediaName,mediatype)}`:
                          `\n"${moniker_name}",${domain},${bias_mean.toFixed(2)},${reliability_mean.toFixed(2)},${getKeyByValue(utils.mediaName,mediatype)}`
                        ));
    
    // create final list of required columns for content pieces
    var metSelect = [22,32];
    var ContentHeading = [];
    
    function getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    }

    if(Object.keys(formValues).length !== 0){
      metSelect = csvSelect;
    }

    filteredContentPieces = filteredContentPieces.map(({moniker_name, url, all_metrics}) => {
      const filteredFlat = metSelect.reduce((obj,key) => ({...obj, [key]: all_metrics[key]}), {});

      ContentHeading = Object.keys(filteredFlat).map(value => parseInt(value));
      ContentHeading = ContentHeading.map((value) => getKeyByValue(utils.metMap,value));

      if (Object.keys(filteredFlat).length !== 0){
        const filteredMetricFlat = Object.values(filteredFlat).join(",");
        return `\n"${moniker_name}","${url}",${filteredMetricFlat}`;
      }
      else {
        return `\n"${moniker_name}","${url}"`;
      }
    });

    excludedContentPieces = excludedContentPieces.map(({moniker_name, url, all_metrics}) => {
      const excludedFlat = metSelect.reduce((obj,key) => ({...obj, [key]: all_metrics[key]}), {});

      if (Object.keys(excludedFlat).length !== 0){
        const excludedMetricFlat = Object.values(excludedFlat).join(",");
        return `\n"${moniker_name}","${url}",${excludedMetricFlat}`;
      }
      else {
        return `\n"${moniker_name}","${url}"`;
      }
    });

    // if audience reach switch is turned on
    if (sourceReach){ 
      filteredSources.unshift("source,domain,bias,reliability,audience size,media type");
      excludedSources.unshift("source,domain,bias,reliability,audience size,media type");
    }
    else {
      filteredSources.unshift("source,domain,bias,reliability,media type");
      excludedSources.unshift("source,domain,bias,reliability,media type");
    }
    
    // if no metric is selected
    if(ContentHeading.length !== 0){
      filteredContentPieces.unshift(`"source","url",${ContentHeading.join(",")}`);
      excludedContentPieces.unshift(`"source","url",${ContentHeading.join(",")}`);
    }
    
    else {
      filteredContentPieces.unshift("source","url");
      excludedContentPieces.unshift("source","url");
    }
    
    // create blob of csv from arrays
    const filteredSourcesBlob = new Blob([filteredSources], {type: 'text/csv'});
    const excludedSourcesBlob = new Blob([excludedSources], {type: 'text/csv'});
    const filteredContentPiecesBlob = new Blob([filteredContentPieces], {type: 'text/csv'});
    const excludedContentPiecesBlob = new Blob([excludedContentPieces], {type: 'text/csv'});

    // zip all blobs into single zip blob
    let zip = new JSZip();
    zip.file("Filtered_Sources.csv",filteredSourcesBlob);
    zip.file("Excluded_Sources.csv",excludedSourcesBlob);
    zip.file("Filtered_Content_Pieces.csv",filteredContentPiecesBlob);
    zip.file("Excluded_Content_Pieces.csv",excludedContentPiecesBlob);

    zip.generateAsync({type: "blob"}).then((content) => {
      FileSaver.saveAs(content, `Chart_Data_Export_${new Date().toString().replace(/ /gi, "_")}.zip`);
    })
  }

  handleZoom(type) {
    this.chart.clickZoom(type);
  }

  async download() {
    this.setState({ isLoading: true });

    var canvas = document.createElement("canvas");
    canvas.style = "display: none";
    canvas.width = width * 2;
    canvas.height = height * 2;
    document.body.appendChild(canvas);
    let ctx = canvas.getContext("2d");
    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    let svg = d3.select(".chart").node(),
      serializer = new XMLSerializer(),
      svgStr = serializer.serializeToString(svg);

    let v = await canvg.Canvg.fromString(ctx, svgStr, {
      anonymousCrossOrigin: true,
      ignoreClear: true,
    });
    await v.render();

    var lnk = document.createElement("a");
    lnk.download = `chart_${new Date().toLocaleString()}.png`;
    lnk.href = canvas.toDataURL("image/png;base64");
    lnk.click();

    canvas.remove();
    this.setState({ isLoading: false });

  }

  render() {
    const { chartLoading, isLoading, sources, articles } = this.state;
    return (
      <div className="main">
        <div className="mainContainer">
          <div className="chartTitle">
            <h1>The Media Bias Chart</h1>
          </div>
          <div className="container">
            <div className="controlsContainer">
              <div className="controlsHeader">Controls </div>
              <div>
                <ControlsForm
                  className="controls"
                  formSubmit={this.formSubmit.bind(this)}
                  chartReset={this.chartReset.bind(this)}
                  sources={sources}
                  articles={articles}
                  chartLoading={chartLoading}
                />                
                
                <Tooltip
                  title="Download Customized Chart Image"
                  color="geekblue"
                  placement="right"
                  >
                    <Button
                    size="large"
                    icon={<DownloadOutlined />}
                    className="downloadButton"
                    onClick={() => this.download()}
                    loading={isLoading}
                    disabled={!chartLoading}
                    >
                      Image
                    </Button>
                  </Tooltip>                
                
                <Tooltip
                  title="Download Customized CSV Files of Filtered & Excluded Lists"
                  color="geekblue"
                  placement="right"
                  >
                    <Button
                      size="large"
                      icon={<DownloadOutlined />}
                      className="downloadButton"
                      onClick={() => this.dataExport()}
                      loading={isLoading}
                      disabled={!chartLoading}
                    >
                      <span>Data</span>
                    </Button>
                </Tooltip>
              </div>
            </div>

            <div className="chart">
              <Spin indicator={antIcon} spinning={!this.state.chartLoading}/>
              <div className="chartContainer"></div>
            </div>            
          </div>
        </div>
      </div>
    );
  }
}

export default App;
