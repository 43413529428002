import * as d3 from "d3";

const sourceToExclude = [];

function reliabilityWeight(reliability) {
    if (reliability >= 24) {
      return 1;
    } else if (reliability >= 16) {
      return 4;
    } else if (reliability >= 8) {
      return 8;
    } else {
      return 16;
    }
  }
  
  function biasWeight(bias) {
    if (-6 <= bias && bias <= 6) {
      return 1;
    } else if (-18 <= bias && bias <= 18) {
      return 4;
    } else if (-30 <= bias && bias <= 30) {
      return 8;
    } else {
      return 16;
    }
  }
  
  function cleanArticles(articles) {
    var sources = {};
  
    articles = articles.filter(
      (article) => !sourceToExclude.includes(article.moniker_name)
    );
  
    articles.forEach((record) => {
      let source = sources[record["moniker_name"]];
      if (!source) {
        source = {
          bias_total: 0,
          bias_count: 0,
          reliability_total: 0,
          reliability_count: 0,
        };
      }
  
      const wBias = biasWeight(record["bias"]);
      const wReliability = reliabilityWeight(record["reliability"]);
  
      source["domain"] = record["main_url"];
      source["moniker_name"] = record["moniker_name"];
      source["image_path"] = record["moniker_name"];
      source["reach"] = record["reach"];
      source["mediatype"] = record["mediatype"];
      source["bias_total"] += record["bias"] * wBias;
      source["bias_count"] += wBias;
      source["reliability_total"] += record["reliability"] * wReliability;
      source["reliability_count"] += wReliability;
      sources[record["moniker_name"]] = source;
    });
  
    sources = Object.keys(sources).map((key) => {
      return {
        ...sources[key],
        bias_mean: sources[key]["bias_total"] / sources[key]["bias_count"],
        reliability_mean:
          sources[key]["reliability_total"] / sources[key]["reliability_count"],
      };
    });
  
    sources.sort((a, b) => a.reach - b.reach);
  
    return { articles, sources };
  }
  
  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 15, // ems
        y = 0,
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy)
          .attr("dx", 0);
  
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy)
            .attr("dx", 0)
            .text(word);
        }
      }
  
      if (lineNumber > 0) {
        const xTranslate = text.node().transform.baseVal[0].matrix.e;
        const yTranslate = ((lineNumber - 1) * lineHeight) / 2;
        text.attr("transform", `translate(${xTranslate}, -${yTranslate})`);
      }
    });
  }
  
  function formatNumber(number) {
    let nums = number.replace(/,/g, "");
    if (!nums) return;
    return parseInt(nums).toLocaleString();
  }

  export { cleanArticles, wrap, formatNumber };