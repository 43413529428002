import * as d3 from "d3";
import { wrap, formatNumber } from "./calculation";
import { mediaName, getKeyByValue } from "./utils";

const APP_BUILD_VERSION = "2.0.0";
const width = 1200;
const height = 725;

const margin = {
  top: 40,
  bottom: 90,
  right: 150,
  left: 170
};

const xDomain = [-42, -30, -18, -6, 0, 6, 18, 30, 42];
const xLabels = [
  "Most Extreme Left",
  "Hyper-Partisan Left",
  "Skews Left",
  "Middle or Balanced Bias",
  "Skews Right",
  "Hyper-Partisan Right",
  "Most Extreme Right",
];
const xLabelsPoints = [-36, -24, -12, 0, 12, 24, 36];

const yDomain = [0, 8, 16, 24, 32, 40, 48, 56, 64];
const yLabels = [
  "Contains Inaccurate / Fabricated Info",
  "Contains Misleading Info",
  "Selective or Incomplete Story / Unfair Persuasion / Propaganda",
  "Opinion or High Variation in Reliability",
  "Analysis or High Variation in Reliability",
  "Complex Analysis or Mix of Fact Reporting and Analysis",
  "Fact Reporting",
  "Original Fact Reporting",
];
const yLabelsPoints = [4, 12, 20, 28, 36, 44, 52, 60];

const xGridDomain = [-30, -18, -6, 0, 6, 18, 30];
const yGridDomain = [8, 16, 24, 32, 40, 48, 56];

const maxReach = 268325000;
const minReach = 100000;

const maxReachRadius = 80;
const minReachRadius = 30;
const logoHeight = 50;
const logoWidth = 50;

const fontFamily = `"Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont,
Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif`;
const fontColor = "#2e2e2e";
class Chart {
  constructor(articles, sources, customMargin) {
    this.sourceReach = false;
    this.lastTransform = { k: 1, x: 0, y: 0 };
    this.articles = articles;
    this.sources = sources;
    this.x = null;
    this.y = null;
    this.zoomed = this.zoomed.bind(this);
    this.plotData = this.plotData.bind(this);
    this.customMargin = customMargin
    
    if(this.customMargin){
      Object.defineProperty(margin,"right",{value: 20});
    }

    Object.freeze(margin);
  }

  create() {
    this.createRoot();
    this.createScales();
    this.createDefs();
    this.createContainers();
    if(!this.customMargin){
      this.createSegments();
    }
    this.createTitles();
    this.createToolTips();
    this.createZoomButtons();
    this.createZoom();
    this.plotData();
    this.zoomContainer.call(this.zoom.transform, d3.zoomIdentity);
  }

  update(articles, sources, config = {}) {
    const { sourceReach } = config;
    this.sourceReach = sourceReach;
    this.articles = articles;
    this.sources = sources;
    this.plotData();
  }

  reset() {
    this.zoomContainer
      .transition()
      .duration(750)
      .call(this.zoom.transform, d3.zoomIdentity);
  }

  clickZoom(type) {
    const newK =
      type === "plus"
        ? Math.min(50, this.lastTransform.k + 2)
        : Math.max(1, this.lastTransform.k - 2);

    this.zoomContainer.transition().duration(300).call(this.zoom.scaleTo, newK);
  }

  destroy() {
    d3.select(this.el).selectAll("svg").remove();
  }

  zoomed() {
    const transform = d3.event.transform;
    const zx = transform.rescaleX(this.x).interpolate(d3.interpolateRound);
    const zy = transform.rescaleY(this.y).interpolate(d3.interpolateRound);

    this.gArticles
      .attr("transform", transform)
      .attr("stroke-width", 5 / transform.k);

    if (this.sourceReach) {
      this.gSourceReach.attr("transform", transform).lower();
      if (transform.k !== this.lastTransform.k) {
        d3.selectAll(".sourceReach").attr("stroke-width", 1 / transform.k);
      }
    }

    this.gSources.attr("transform", transform);
    if (transform.k !== this.lastTransform.k) {
      d3.selectAll(".sourceAverage")
        .attr("width", logoWidth / transform.k)
        .attr("height", logoHeight / transform.k)
        .attr(
          "x",
          (d) => this.x(d.bias_mean) - logoWidth / 2 / transform.k
        )
        .attr(
          "y",
          (d) => this.y(d.reliability_mean) - logoHeight / 2 / transform.k
        );
    }
    if(!this.customMargin){
      this.gSegmentGreen.attr("transform", transform);
      this.gSegmentYellow.attr("transform", transform);
      this.gSegmentOrange.attr("transform", transform);
      this.gSegmentRed.attr("transform", transform);
    }
    
    if (transform.k !== this.lastTransform.k) {
      this.segmentContainer.attr(
        "style",
        `stroke-width: ${Math.ceil(3 / transform.k)}`
      );
    }

    this.gx.call(xAxis, zx);
    this.gy.call(yAxis, zy);

    this.gxDesc.call(xAxisDesc, zx);
    this.gyDesc.call(yAxisDesc, zy);

    this.gGrid.call(grid, zx, zy);

    this.lastTransform = transform;
  }

  plotData() {
    // articles
    this.gArticles
      .selectAll(".sourceArticle")
      .data(this.articles)
      .join("path")
      .attr("class", "sourceArticle")
      .attr("d", (d) => `M${this.x(d.bias)},${this.y(d.reliability)}h0`)
      .attr("stroke", (d) => {
        if (this.sources.length < 10) {
          return this.color(d.moniker_name);
        } else {
          return "gainsboro";
        }
      })
      .on("mouseover", function (d) {
        articleTooltip(d3.event, d);
        d3.select(this).attr("stroke", "black");
        d3.select(this).raise();
      })
      .on("mousemove", (d) => articleTooltip(d3.event, d))
      .on("mouseout", (d) => this.hoverArticle(d))
      .on("click", (d) => window.open(d.url, "_blank"));

    // sources
    this.gSources
      .selectAll(".sourceAverage")
      .data(this.sources)
      .join("image")
      .attr("class", "sourceAverage")
      .attr(
        "x",
        (d) => this.x(d.bias_mean) - logoWidth / 2 / this.lastTransform.k
      )
      .attr(
        "y",
        (d) =>
          this.y(d.reliability_mean) - logoHeight / 2 / this.lastTransform.k
      )
      .attr("width", logoWidth / this.lastTransform.k)
      .attr("height", logoHeight / this.lastTransform.k)
      .attr("opacity", 0.75)
      .attr("href", function (d) {
        if (d.image_path) {          
          return `/mediaicons/${d.image_path}`;
        }
      })
      .on("mouseover", function (d) {
        sourceTooltip(d3.event, d);
        d3.select(this).attr("opacity", 1);
        d3.select(this).raise();
      })
      .on("mousemove", (d) => sourceTooltip(d3.event, d))
      .on("mouseout", function () {
        d3.select(".svg-tooltip.toolTipSource").style("visibility", "hidden");
        d3.select(this).attr("opacity", 0.75);
      })
      .on("click", function (d) {
        if (d.domain) {
          return window.open(`${d.domain}`, "_blank");
        }
      });

    // reach
    if (this.sourceReach) {
      this.gSourceReach
        .selectAll(".sourceReach")
        .data(this.sources.filter((source) => source.reach))
        .join("circle")
        .attr("class", "sourceReach")
        .attr("cx", (d) => this.x(d.bias_mean))
        .attr("cy", (d) => this.y(d.reliability_mean))
        .attr("r", (d) => this.scaleReach(Math.min(d.reach, maxReach)))
        .attr("opacity", 0.9)
        .attr("stroke", "#323232")
        .attr("stroke-dasharray", "2 1")
        .attr("fill", "#ededed");

      this.gSourceReach.attr("transform", this.lastTransform).lower();
      d3.selectAll(".sourceReach").attr(
        "stroke-width",
        1 / this.lastTransform.k
      );
    } else {
      this.gSourceReach.selectAll(".sourceReach").remove();
    }
  }

  createRoot() {
    this.el = d3
      .select("div.chartContainer")
      .append("svg")
      .attr("viewBox", [0, 0, width, height])
      .attr("class", "chartSVG")
      .style("fill", "white");
  }

  createScales() {
    this.color = d3
      .scaleOrdinal()
      .domain(this.sources.map((d) => d.moniker_name).sort())
      .range(d3.schemeTableau10);

    this.x = d3
      .scaleLinear()
      .domain([-42, 42])
      .range([margin.left, width - margin.right]);

    this.y = d3
      .scaleLinear()
      .domain([0, 64])
      .range([height - margin.bottom, margin.top]);

    this.scaleReach = d3
      .scaleLinear()
      .domain([minReach, maxReach])
      .range([minReachRadius, maxReachRadius]);
  }

  createDefs() {
    const defs = this.el.append("defs");

    defs
      .append("clipPath")
      .attr("id", "clipData")
      .append("rect")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom);

    defs
      .append("clipPath")
      .attr("id", "clipXAxis")
      .append("rect")
      .attr("x", margin.left - 15)
      .attr("y", 0)
      .attr("width", width - margin.left - margin.right + 30)
      .attr("height", height)
      .attr("style", "fill: transparent; stroke-width: 1; stroke: rgb(0,0,0)");

    defs
      .append("clipPath")
      .attr("id", "clipYAxis")
      .append("rect")
      .attr("x", 0)
      .attr("y", margin.top - 5)
      .attr("width", width)
      .attr("height", height - margin.top - margin.bottom + 10)
      .attr("style", "fill: transparent; stroke-width: 1; stroke: rgb(0,0,0)");
  }

  createContainers() {
    this.zoomContainer = this.el.append("g").attr("class", "zoomableArea");
    this.zoomContainer
      .append("rect")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)
      .attr(
        "style",
        "fill:transparent; stroke-width: 1; stroke: rgba(0, 0, 0);"
      );

    this.segmentContainer = this.zoomContainer
      .append("g")
      .attr("style", "stroke-width: 3")
      .attr("clip-path", "url(#clipData)");

    this.zoomButtonContainer = this.zoomContainer
      .append("g")
      .attr("class", "zoomRefContainer")
      .attr("clip-path", "url(#clipData)");

    this.gGrid = this.zoomContainer
      .append("g")
      .attr("clip-path", "url(#clipData)");
    const gxContainer = this.zoomContainer
      .append("g")
      .attr("clip-path", "url(#clipXAxis)");
    const gyContainer = this.zoomContainer
      .append("g")
      .attr("clip-path", "url(#clipYAxis)");

    this.gx = gxContainer.append("g").attr(
      "style",
      `
          color: ${fontColor};
          font-family: ${fontFamily};
          font-size: 12px;
          `
    );
    this.gy = gyContainer.append("g").attr(
      "style",
      `
          color: ${fontColor};
          font-family: ${fontFamily};
          font-size: 12px;
          `
    );

    this.gxDesc = gxContainer.append("g").attr(
      "style",
      `
          color: ${fontColor};
          font-family: ${fontFamily};
          font-size: 12px;
          `
    );
    this.gyDesc = gyContainer.append("g").attr(
      "style",
      `
          color: ${fontColor};
          font-family: ${fontFamily};
          font-size: 12px;
          `
    );

    this.dataContainer = this.zoomContainer
      .append("g")
      .attr("clip-path", "url(#clipData)");
    this.gArticles = this.dataContainer
      .append("g")
      .attr("stroke-linecap", "round");

    this.gSourceReach = this.dataContainer.append("g");
    this.gSources = this.dataContainer.append("g");
  }

  createSegments() {
    // green
    this.gSegmentGreen = this.segmentContainer
      .append("rect")
      .attr("clip-path", "url(#clipData)")
      .attr("x", this.x(-16.5))
      .attr("y", this.y(63))
      .attr("height", this.y(40) - this.y(63))
      .attr("width", this.x(16.5) - this.x(-16.5))
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("style", "fill:none; stroke:#27AE60; opacity:0.5;")
      .attr("stroke-dasharray", "4");

    // yellow
    this.gSegmentYellow = this.segmentContainer
      .append("rect")
      .attr("x", this.x(-22))
      .attr("y", this.y(46))
      .attr("height", this.y(24) - this.y(46))
      .attr("width", this.x(22) - this.x(-22))
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("style", "fill:none; stroke:#F1C40F; opacity:0.5;")
      .attr("stroke-dasharray", "4 2 1");

    // orange
    this.gSegmentOrange = this.segmentContainer
      .append("polyline")
      .attr(
        "points",
        `
          ${this.x(-41.5)},${this.y(46)}
          ${this.x(-41.5)},${this.y(16)},
          ${this.x(41.5)},${this.y(16)},
          ${this.x(41.5)},${this.y(46)},
          ${this.x(22)},${this.y(46)},
          ${this.x(22)},${this.y(24)},
          ${this.x(-22)},${this.y(24)},
          ${this.x(-22)},${this.y(46)},
          ${this.x(-41.5)},${this.y(46)}
        `
      )
      .attr("style", "fill:none; stroke:#E67E22; opacity:0.5;")
      .attr("stroke-dasharray", "4 1 1");

    // red
    this.gSegmentRed = this.segmentContainer
      .append("rect")
      .attr("x", this.x(-41.5))
      .attr("y", this.y(16))
      .attr("height", this.y(0.5) - this.y(16))
      .attr("width", this.x(41.5) - this.x(-41.5))
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("style", "fill:none; stroke:#E74C3C;opacity:0.5;")
      .attr("stroke-dasharray", "4 2 2");
  }

  createTitles() {
    // xAxisTitle
    this.el
      .append("image")
      .attr("width", width - margin.left - margin.right)
      .attr("height", 30)
      .attr("x", margin.left)
      .attr("y", height - margin.bottom / 1.5)
      .attr("href", `${process.env.PUBLIC_URL}/images/xAxisTitle.png`);

    // // Add copyright
    this.copyrightContainer = this.el
      .append("g")
      .append("text")
      .attr("width", width - margin.left - margin.right)
      .attr("height", 30)
      .attr("x", width / 2)
      .attr("y", height - margin.bottom / 4)
      .attr("xml:space", "preserve")
      .attr(
        "style",
        `
            fill: ${fontColor};
            font-family: ${fontFamily};
            font-size: 10px;
            text-anchor: middle;
            `
      );

    this.copyrightContainer
      .append("tspan")
      .text(`Media Bias Chart (R) Licensed Copy. Copyright`);
    this.copyrightContainer
      .append("tspan")
      .text(`Ad Fontes Media, Inc`)
      .attr("dx", "4px")
      .attr(
        "style",
        `fill: #1890ff; cursor: pointer; text-decoration: underline`
      )
      .on("click", () =>
        window.open("https://www.adfontesmedia.com/", "_blank")
      );
    this.copyrightContainer
      .append("tspan")
      .attr("dx", "4px")
      .text(`${new Date().getFullYear()}`);
    this.copyrightContainer
      .append("tspan")
      .attr("dx", "4px")
      .text(`- Application Version : ${APP_BUILD_VERSION}`);

    // yAxisTitle
    this.el
      .append("image")
      .attr("width", 30)
      .attr("height", height - margin.bottom - margin.top)
      .attr("x", (margin.left * 2) / 3 - 5)
      .attr("y", margin.top)
      .attr("href", `${process.env.PUBLIC_URL}/images/yAxisTitle.png`);

    // Add typeKey
    if(!this.customMargin){
      d3.xml(`${process.env.PUBLIC_URL}/images/typeKey.svg`).then((data) => {
        this.el.node().append(data.documentElement);
        this.el
          .select("#typeKey")
          .attr("viewBox", "0,0,210,520")
          .attr("x", width - margin.right - 30)
          .attr("y", margin.top / 2)
          .attr("width", 210)
          .attr("height", 320);
      });
    }
  }

  createZoom() {
    this.zoom = d3
      .zoom()
      .scaleExtent([1, 50])
      .extent([
        [margin.left, margin.top],
        [width - margin.right, height - margin.bottom],
      ])
      .translateExtent([
        [margin.left, margin.top],
        [width - margin.right, height - margin.bottom],
      ]);

    this.zoomContainer.call(this.zoom.on("zoom", this.zoomed));
  }

  createToolTips() {
    this.toolTipSource = d3
      .select(".chartContainer")
      .append("div")
      .attr("class", "svg-tooltip toolTipSource");
    this.toolTipSource.append("div").attr("class", "toolTipSource");
    this.toolTipSource.append("div").attr("class", "toolTipDomain");
    this.toolTipSource.append("div").attr("class", "toolTipQuality");
    this.toolTipSource.append("div").attr("class", "toolTipBias");
    this.toolTipSource.append("div").attr("class", "toolTipReach");
    this.toolTipSource.append("div").attr("class", "toolTipMediaType");

    this.toolTipArticle = d3
      .select(".chartContainer")
      .append("div")
      .attr("class", "svg-tooltip toolTipArticle");
    this.toolTipArticle.append("div").attr("class", "toolTipSource");
    this.toolTipArticle.append("div").attr("class", "toolTipDomain");
    this.toolTipArticle.append("div").attr("class", "toolTipQuality");
    this.toolTipArticle.append("div").attr("class", "toolTipBias");
    this.toolTipArticle.append("div").attr("class", "toolTipReach");
  }

  hoverArticle(article) {
    d3.select(".svg-tooltip.toolTipArticle").style("visibility", "hidden");
    d3.selectAll(".sourceArticle")
      .filter(function (d) {
        return d.moniker_name === article.moniker_name;
      })
      .attr("stroke", (d) => {
        if (this.sources.length < 10) {
          return this.color(d.moniker_name);
        } else {
          return "gainsboro";
        }
      });
  }

  createZoomButtons() {
    d3.xml(`${process.env.PUBLIC_URL}/images/zoomButtons.svg`).then((data) => {
      this.zoomButtonContainer.node().append(data.documentElement);
      this.el
        .select("#zoomButtons")
        .attr("class", "zoomRef")
        .attr("x", width - margin.right - 30)
        .attr("y", margin.top / 2 + 10)
        .attr("width", 50)
        .attr("height", 100);

      this.zoomButtonContainer
        .select(".zoomPlus")
        .on("click", () => this.clickZoom("plus"));

      this.zoomButtonContainer
        .select(".zoomMinus")
        .on("click", () => this.clickZoom("minus"));
    });
  }
}

// Define xAxis
const xAxis = (g, x) => {
  g.attr("transform", `translate(0, ${height - margin.bottom})`).call(
    d3
      .axisBottom(x)
      .tickValues(xDomain)
      .tickSize(0)
      .tickPadding(10)
      .tickFormat(d3.format(".1f"))
  );
};

// Define yAxis
const yAxis = (g, y) =>
  g
    .attr("transform", `translate(${margin.left}, 0)`)
    .call(
      d3
        .axisLeft(y)
        .tickValues(yDomain)
        .tickSize(0)
        .tickPadding(10)
        .tickFormat(d3.format(".1f"))
    );

// Define xAxisDesc
const xAxisDesc = (g, x) =>
  g
    .attr("transform", `translate(0, ${margin.top})`)
    .call(
      d3
        .axisBottom(x)
        .tickValues(xLabelsPoints)
        .tickSize(0)
        .tickPadding(10)
        .tickFormat((d, i) => xLabels[i])
    )
    .selectAll(".tick text")
    .attr("transform", `translate(0,-${margin.top - 10})`);

// Define xAxisDesc
const yAxisDesc = (g, y) =>
  g
    .attr("transform", `translate(${margin.left}, 0)`)
    .call(
      d3
        .axisLeft(y)
        .tickValues(yLabelsPoints)
        .tickSize(0)
        .tickPadding(10)
        .tickFormat((d, i) => yLabels[i])
    )
    .selectAll(".tick text")
    .attr("transform", `translate(-${margin.left * (2 / 3)},0)`)
    .attr("style", "text-anchor: middle; ")
    .call(wrap, 100);

// Define grid
const grid = (g, x, y) =>
  g
    .attr("stroke", "currentColor")
    .attr("stroke-opacity", 0.1)
    .call((g) =>
      g
        .selectAll(".x")
        .data(xGridDomain)
        .join(
          (enter) => enter.append("line").attr("class", "x"),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("x1", (d) => 0.5 + x(d))
        .attr("x2", (d) => 0.5 + x(d))
        .attr("y1", margin.top)
        .attr("y2", height - margin.bottom)
        .attr("stroke-opacity", (d) => (d === 0 ? 0.3 : 0.1))
    )
    .call((g) =>
      g
        .selectAll(".y")
        .data(yGridDomain)
        .join(
          (enter) => enter.append("line").attr("class", "y"),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("y1", (d) => 0.5 + y(d))
        .attr("y2", (d) => 0.5 + y(d))
        .attr("x1", margin.left)
        .attr("x2", width - margin.right)
    );

const sourceTooltip = (e, d) => {
  let toolTip = d3.select(".svg-tooltip.toolTipSource");
  toolTip
    .style("position", "absolute")
    .style("visibility", "visible")
    .style("top", d3.event.pageY - 10 + "px")
    .style("left", d3.event.pageX + 10 + "px");

  toolTip.select(".toolTipSource").text(`Name: ${d.moniker_name}`);
  toolTip.select(".toolTipDomain").text(`Domain: ${d.domain}`);
  toolTip.select(".toolTipQuality").text(`Reliability: ${d.reliability_mean.toFixed(2)}`);
  toolTip.select(".toolTipBias").text(`Bias: ${d.bias_mean.toFixed(2)}`);
  toolTip.select(".toolTipReach").text((d.reach === 0) ? `Audience Size: No data` : `Audience Size: ${formatNumber(String(d.reach))}`);
  toolTip.select(".toolTipMediaType").text(`Media Type: ${String(getKeyByValue(mediaName,d.mediatype))}`);
};

const articleTooltip = (e, d) => {
  let toolTip = d3.select(".svg-tooltip.toolTipArticle");
  toolTip
    .style("position", "absolute")
    .style("visibility", "visible")
    .style("top", d3.event.pageY - 10 + "px")
    .style("left", d3.event.pageX + 10 + "px");

  toolTip.select(".toolTipSource").text(`Name: ${d.moniker_name}`);
  toolTip.select(".toolTipDomain").text(`Domain: ${d.domain}`);
  toolTip
    .select(".toolTipQuality")
    .text(`Reliability: ${d.reliability.toFixed(2)}`);
  toolTip.select(".toolTipBias").text(`Bias: ${d.bias.toFixed(2)}`);
  toolTip.select(".toolTipReach").text(`Content Url: ${d.url}`);
};

export { Chart, height, width };
