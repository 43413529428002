const mediaName = {
  "Unknown": 1,
  "Website / Articles": 2,
  "TV / Video": 3,
  "Podcast / Audio": 4
}

const metMap = {
  "bias": 22,
  "reliability": 32,
  "comparison": 15,
  "expression": 26,
  "headline": 28,
  "poli term": 16,
  "veracity": 27,
  "poli pos": 17
}

const metricOptions = [
  {label: 'Bias', value: 22, disabled: false},
  {label: 'Reliability', value: 32, disabled: false},
  {label: 'Comparison', value: 15, disabled: false},
  {label: 'Expression', value: 26, disabled: false},
  {label: 'Headline', value: 28, disabled: false},
  {label: 'Poli Term', value: 16, disabled: false},
  {label: 'Veracity', value: 27, disabled: false},
  {label: 'Poli Pos', value: 17, disabled: false},
];

const mediaType = [2,3,4];
const enableValues = [0,1,1,1];

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export { mediaName, mediaType, enableValues, metricOptions, metMap, getKeyByValue };